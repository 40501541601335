import Chart from "chart.js/auto";
import "./dna.css";
import { useEffect } from "react";

export default function Dna() {

    useEffect(() => {
        const elem = document.getElementById("dna__chart") as HTMLCanvasElement;
        elem.width = elem.offsetWidth;
        elem.height = elem.offsetHeight;

        const data = {
            labels: [
                'Organization',
                'Mnemonics',
                'Elaboraton',
                'Recall',
                'Group',
                'Self-management',
                'Reflection'
            ],
            datasets: [{
                label: 'My Learning DNA',
                data: [65, 59, 90, 81, 56, 55, 40],
                fill: true,
                backgroundColor: 'rgb(245, 203, 121, 0.2)',
                borderColor: 'rgb(245, 203, 121)',
                pointBackgroundColor: 'rgb(255, 99, 132)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgb(255, 99, 132)'
              
            },]
        };

        if (elem) {
            Chart.getChart(elem)?.destroy();

            new Chart(
                elem,
                {
                    type: 'radar',
                    data: data,
                    options: {
                        elements: {
                            line: {
                                borderWidth: 4
                            }
                        }
                    },
                }
            );
        }
    });

    return (
        <div className={"dna__container"}>
            <div className={"dna"}>
                <canvas id={"dna__chart"} width={1000} height={1000} ></canvas>
            </div>
        </div>
    );
}