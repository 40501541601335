import { Button, Form, Stack } from "react-bootstrap";
import { CourseData, deleteCourse, patchCourse } from "../../../logic/course";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Modal from "react-bootstrap/Modal";
import TrashIcon from "./trash-bin.svg";
import PenIcon from "./pen.svg";
import FavoriteActive from "./favorite_orange.svg";
import FavoriteInactive from "./favorite_grey.svg";
import "./course_settings.css";


export interface CourseSettingsProps {
    show: boolean;
    hide(): void;
    deletecourse(): void;
    course: CourseData
}

export default function CourseSettings(props: CourseSettingsProps) {
    const { user, getAccessTokenSilently } = useAuth0();
    const [deleteNow, setDeleteNow] = useState(false);
    const [disabledInput, setDisabledInput] = useState(true);
    const [courseName, setCourseName] = useState(props.course.name);
    const [favorite, setFavorite] = useState<boolean>(props.course.favorite || false);


    const onDeleteCourseCallback = () => {
        setDeleteNow(!deleteNow);
    };

    const onFinalCourseDelete = async () => {
        if (user) {
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: "https://platform.lognitiv.com/api/v1",
                    },
                });

                const id = user?.sub?.split("|").pop() || "NONE";
                await deleteCourse(accessToken, {
                    userId: id,
                    name: props.course.name,
                    courseId: props.course.courseId,
                    difficulty: undefined,
                    favorite: undefined,
                    updated: undefined,
                    created: undefined
                })

                props.deletecourse();
            } catch (e) {
                console.log(e);
            }
        }
    };

    const onCloseCallback = () => {
        setDeleteNow(false);
        setCourseName(courseName);
        setDisabledInput(true);
        props.hide();
    }

    const onEditNameCallback = () => {
        setDisabledInput(!disabledInput);
    };

    const onEditCourseName = (newValue: string) => {
        setCourseName(newValue);
    };

    const onSaveCallback = async () => {
        if (user && (courseName !== props.course.name)) {
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: "https://platform.lognitiv.com/api/v1",
                    },
                });

                const id = user?.sub?.split("|").pop() || "NONE";
                await patchCourse(accessToken, {
                    userId: id,
                    name: courseName,
                    courseId: props.course.courseId,
                    difficulty: undefined,
                    favorite: undefined,
                    updated: undefined,
                    created: undefined
                })

                props.course.name = courseName;
            } catch (e) {
                console.log(e);
            }
        }
    };

    const onFavoriteClick = async () => {
        if (user) {
            try {
                const newFav = !favorite;
                setFavorite(newFav);
                props.course.favorite = newFav;

                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: "https://platform.lognitiv.com/api/v1",
                    },
                });

                await patchCourse(accessToken, props.course)
            } catch (e) {
                console.log(e);
            }
        }
    };

    return (
        <Modal
            show={props.show}
            onHide={onCloseCallback}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className={"settings__title"}>
                    <div className={"settings__title-row"}>
                        <Form.Control
                            type="text"
                            value={courseName}
                            aria-label="Disabled input example"
                            className={"settings__title-input"}
                            onInput={(e) => onEditCourseName(e.currentTarget.value)}
                            minLength={1}
                            maxLength={10}
                            disabled={disabledInput}
                        />
                        <img src={PenIcon} onClick={onEditNameCallback} className="penicon" />
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stack gap={2}>
                    <button style={{ display: "flex" }} className={"settings_course-favorite"} onClick={onFavoriteClick}>
                        {favorite ? <img src={FavoriteActive} /> : <img src={FavoriteInactive} />}
                        {favorite ? <p>Favorite</p> : <p>Set as Favorite</p>}
                    </button>
                    <div>Created</div>
                    <div>Updated</div>
                    <div>Size</div>
                    <button className={"settings__delete-button"} onClick={onDeleteCourseCallback}>
                        <div className={"delete-button__row"}>
                            <img src={TrashIcon} />
                            Delete
                        </div>
                    </button>
                </Stack>
            </Modal.Body>
            <Modal.Footer>
                {deleteNow && <Button variant="danger" className="settings__delete-now" onClick={onFinalCourseDelete}>Delete now</Button>}
                <Button variant="success" className="savesettingschanges" onClick={onSaveCallback}>Save</Button>
                <Button onClick={onCloseCallback} className="closesettings">Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
