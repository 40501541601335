import { CourseData } from "../../logic/course";
import { useState } from "react";
import PlayIcon from "./play.svg";
import CourseAnalytics from "./statistics.svg";
import Settings from "./setting.svg";
import Stack from 'react-bootstrap/Stack';
import CourseSettings from "../modals/settings/CourseSettings";
import "./course_overview.css";


interface CourseProps {
    course: CourseData
    deleteCourseCallback(): void
    reloadCallback(): void
}

export default function CourseOverview({ course, deleteCourseCallback, reloadCallback }: CourseProps) {
    const [showSettings, setShowSettings] = useState(false);
    const [courseDetails] = useState<CourseData>(course);


    return (
        <>
            <div className={"overview"}>
                <div className={"overview__content"}>
                    <div style={{ display: "flex", justifyContent: "center", maxWidth: "100%" }}>
                        <p>{courseDetails.name}</p>
                    </div>
                    <Stack direction="horizontal" gap={3} className={"justify-content-center"}>
                        <img className={"overview__icon"} src={PlayIcon} />
                        <img className={"overview__icon"} src={CourseAnalytics} />
                        <img className={"overview__icon"} src={Settings} onClick={() => setShowSettings(true)} />
                    </Stack>
                </div>
            </div>
            <CourseSettings show={showSettings}
                hide={() => {
                    setShowSettings(false);
                    //TODO: reload only on changes
                    reloadCallback();
                }}
                deletecourse={() => {
                    setShowSettings(false);
                    deleteCourseCallback();
                }}
                course={courseDetails} />
        </>
    );
}