import { useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import Form from 'react-bootstrap/Form';

export default function UserImageConfig({ actualImage, sliderValue, showModal, showUserImageModalCallback, saveImageCallback }) {
    const [file, setFile] = useState(actualImage);
    const [slideValue, setSlideValue] = useState(sliderValue);
    const [originalImage, setOriginalImage] = useState(actualImage);
    const editor = useRef(null);

    //TODO: BUG, original image lost

    const onModalCloseCallback = () => {
        setFile(initialImage);
        showUserImageModalCallback();
    };

    return (
        <Modal
            show={showModal}
            onHide={showUserImageModalCallback}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className={"settings__title"}>
                    <div>User Image</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Dropzone onDrop={acceptedFiles => setFile(acceptedFiles[0])}>
                    {({ getRootProps, getInputProps }) => (
                        <section style={{ display: "flex", justifyContent: "center" }}>
                            <div {...getRootProps()}>
                                <AvatarEditor
                                    ref={editor}
                                    width={250}
                                    height={250}
                                    image={file}
                                    border={50}
                                    borderRadius={150}
                                    scale={slideValue / 10}
                                    rotate={0}
                                    onImageReady={() => setOriginalImage(editor.current.getImage().toDataURL())}
                                />
                                <input {...getInputProps()} />
                                <p>Select a picture</p>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Form.Range
                        defaultValue={slideValue}
                        onChange={(e) => setSlideValue(e.target.value)}
                    />
                    <div style={{ display: "flex" }}>
                        <button onClick={() => {
                            if (editor) {
                                const canvasScaled = editor.current.getImageScaledToCanvas();
                                saveImageCallback(canvasScaled.toDataURL(), originalImage, slideValue);
                            }
                        }}>Save</button>
                        <Button onClick={showUserImageModalCallback} className="closesettings">Close</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}