import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate = ({ children }: {children: any}) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain="login.lognitiv.com"
      clientId={process.env.AUTH_CLIENT_ID || "NONE"}
      authorizationParams={{
        audience: "https://platform.lognitiv.com/api/v1",
        redirect_uri: process.env.AUTH_REDIRECT_URI,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
