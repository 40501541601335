import { createBrowserRouter } from "react-router-dom";
import { lazy, Suspense } from 'react';
import { AuthenticationGuard } from "./components/auth/AuthenticationGuard";
import { Auth0ProviderWithNavigate } from "./components/auth/AuthWithNavigate";
import App from "./App";
import Entry from "./views/entry/Entry";
import NotFound from "./views/error/NotFound";
import Callback from "./components/callback/Callback";
import Verification from "./views/verification/Verification";

const LockedDashboard = lazy(() => import("./views/dashboard/LockedDashboard"));
const AllCourses = lazy(() => import("./views/courseoverview/AllCourses"));
const DriverSeat = lazy(() => import("./views/driver/DriverSeat"));


export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    ),
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Entry />,
      },
      {
        path: "dashboard",
        element: <Suspense fallback={<div>Loading...</div>}><AuthenticationGuard component={LockedDashboard} /></Suspense>,
      },
      {
        path: "courses",
        element: <Suspense fallback={<div>Loading...</div>}><AuthenticationGuard component={AllCourses} /></Suspense>,
      },
      {
        path: "driver",
        element: <Suspense fallback={<div>Loading...</div>}><AuthenticationGuard component={DriverSeat} /></Suspense>,
      },
      {
        path: "callback",
        element: <Callback />,
      },
      {
        path: "verification",
        element: <Verification />,
      },
    ],
  },
]);
