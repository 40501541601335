import { useState } from "react";
import AddCourse from "./add.svg";
import "./new_course.css";

interface NewCourseProps {
    onNewCourseCallback(name: string): void;
    containerStyle?: React.CSSProperties
    contentStyle?: React.CSSProperties
}

export default function NewCourse({onNewCourseCallback, containerStyle, contentStyle}: NewCourseProps) {
    const [enterName, setEnterName] = useState<boolean>(false);

    const onAddCallback = () => {
        setEnterName(true);
    };

    const onCreateClick = () => {
        const inputField = document.getElementById("name") as HTMLInputElement;
        onNewCourseCallback(inputField?.value || "ERROR");
        setEnterName(false);
    };

    const onCancelClick = () => {
        setEnterName(false);
    };

    return (
        <div className={"new-course"} style={containerStyle}>
            <div className={"new-course__content"} style={contentStyle}>
                {enterName ?
                    <div className={"new-course__form"}>
                        <input type="text" id="name" name="name" required minLength={1} maxLength={20} size={20}/>
                        <div className={"new-course__submit"}>
                            <div className={"new-course__submit-create"} onClick={onCreateClick}>Create</div>
                            <div className={"new-course__submit-cancel"} onClick={onCancelClick}>Cancel</div>
                        </div>
                    </div>
                    : <img className={"new-course__icon"} src={AddCourse} onClick={onAddCallback}/>
                }
            </div>
        </div>
    );
}
