import DashboardSide from "../../components/side/DashboardSide";
import DashboardFooter from "../../components/footer/DashboardFooter";
import Dna from "../../components/dna/Dna";
import "./dashboard.css";
import Header from "../../components/header/Header";

export default function Dashboard() {
    return (
        <div className={"dashboard__view"}>
            <div className={"dashboard__container"}>
                <Header />
                <section className={"dashboard"}>
                    <DashboardSide />
                    <main className={"dashboard__dna"}>
                        <Dna />
                    </main>
                    <DashboardFooter />
                </section>
            </div>
        </div>
    );
}