import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CloseButton, OverlayTrigger, Popover } from "react-bootstrap";
import VerifyMailImg from "./verify_mail.svg";
import Logo from "./logo_white.png";
import "./verification.css";


export default function Verification() {
    const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
    const navigate = useNavigate();
    const [addressResent, setAddressResent] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);

    const resendVerificationEmailCallback = async () => {
        if (isAuthenticated && user) {
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: "https://platform.lognitiv.com/api/v1",
                    },
                });

                const id = user?.sub?.split("|").pop() || "NONE";
                const resendVerification = `${process.env.BACKEND_API_URI}/api/v1/verification/user`;

                const verificationResponse = await fetch(resendVerification, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${accessToken}`,
                        "X-User-Id": id
                    },
                    method: "POST",
                    body: JSON.stringify({ userId: id })
                });

                if (verificationResponse.status > 399) {
                    throw Error("Unable to send verification e-mail")
                }

                const verification: { email: string } = await verificationResponse.json();
                setAddressResent(verification.email);
            } catch (e) {
                if (e instanceof Error) {
                    console.log("could not verify e-mail")
                }
            }
        }
    };

    const fetchVerificationMail = async () => {
        if (isAuthenticated && user) {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: "https://platform.lognitiv.com/api/v1",
                },
            });

            const id = user?.sub?.split("|").pop() || "NONE";
            const accountMail = `${process.env.BACKEND_API_URI}/api/v1/account/${id}/email`;

            const rawMailResponse = await fetch(accountMail, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`,
                    "X-User-Id": id
                }
            });

            if (rawMailResponse.status > 399) {
                throw Error("Unable to send verification e-mail")
            }

            const mailResponse: { email: string } = await rawMailResponse.json();
            if (mailResponse) {
                setEmail(mailResponse.email);
            }
        }
    };

    useEffect(() => {
        if (user && user["verified"]) {
            navigate("/dashboard");
        }

        fetchVerificationMail().catch((e) => console.log(e));
    });

    if (user && !user["verified"]) {
        return (
            <div className={"verification"}>
                <div className={"verification__top-spacer"} />
                <div className={"verification__logo-row"}>
                    <img src={Logo} className={"verification__logo"} />
                    <div style={{ right: 30, position: "absolute" }}>
                        <OverlayTrigger
                            trigger="click"
                            key={"bottom"}
                            placement={"bottom"}
                            overlay={
                                <Popover id={"popover-positioned-bottom"}>
                                    <Popover.Header as="h3">{"Popover bottom"}</Popover.Header>
                                    <Popover.Body>
                                        <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                                            Log Out
                                        </button>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <CloseButton />
                        </OverlayTrigger>
                    </div>
                </div>
                <img src={VerifyMailImg} className={"verification__mail-icon"} />
                <div className={"verification__middle-spacer"} />
                <div className={"verification__text-col"}>
                    <h1>Please confirm your email to continue...</h1>
                    <h3>We have sent you an e-mail to confirm that your e-mail address {email} is correct.</h3>
                    <h3>Please verify this by clicking on the link in the email.</h3>
                    <button onClick={resendVerificationEmailCallback}>Resend confirmation email</button>
                    {addressResent && <h3>Verification mail sent to: {addressResent}</h3>}
                </div>
            </div>
        );
    } else {
        // TODO: Design Error page
        return (<div>Loading...</div>)
    }
}