import { Suspense, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import PaymentGuard from "../../components/payment/PaymentGuard";
import Dashboard from "./Dashboard";


export default function LockedDashboard() {

  const { user } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if(user && !user["verified"]) {
      navigate("/verification");
    }
  });

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <PaymentGuard component={<Dashboard />} />
    </Suspense>
  );
}
