import HeaderLogo from "../logo/HeaderLogo";
import HeaderActions from "./HeaderActions";
import "./header.css";

export default function Header() {
    return (
        <div className={"header"}>
            <HeaderLogo />
            <div className={"header__actions-container"}>
                <HeaderActions />
            </div>
        </div>
    );
}