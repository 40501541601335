import { Modal } from "react-bootstrap";
import Calendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';

interface LognitivCalendarProps {
    showCalendar: boolean;
    hideCalendar(): void;
}

export default function LognitivCalendar({ showCalendar, hideCalendar }: LognitivCalendarProps) {
    return (
        <Modal
            show={showCalendar}
            onHide={hideCalendar}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className={"settings__title"}>
                    <h4>Calendar</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Calendar
                        usageStatistics={false}
                        height="30em"
                        view="month"
                        month={{
                            dayNames: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
                            visibleWeeksCount: 3,
                        }} />
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    );
}