import { ReactNode, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Payment from "./Payment";


interface PaymentGuardProps {
  component: ReactNode;
}

type LockstatusResponse = {
  locked: boolean;
}

export default function PaymentGuard({ component }: PaymentGuardProps) {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [locked, setLocked] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);

  const onFSPopupClosed = (order?: {id?: string, reference?: string}) => {
    if(order && order.id && order.id != "") {
      setReload(true);
    }
  };

  const loadSblLibrary = () => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "fsc-api";
    script.setAttribute("data-continuous", "true");
    script.src =
      "https://sbl.onfastspring.com/sbl/1.0.1/fastspring-builder.min.js";
    script.dataset.storefront =
      "lognitiv.onfastspring.com/popup-lognitiv";

    script.setAttribute("data-data-callback", "fastSpringCallBack");
    script.setAttribute("data-popup-closed", "onFSPopupClosed");

    document.head.appendChild(script);
  };

  useEffect(() => {

    if (user && user["verified"]) {
      const getLockedStatus = async () => {

        const storeScript = document.getElementById("fsc-api");
        window.onFSPopupClosed = onFSPopupClosed;

        if(!storeScript) {
          loadSblLibrary();
        }

        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: "https://platform.lognitiv.com/api/v1",
            },
          });

          const id = user?.sub?.split("|").pop() || "NONE";
          const userLockStatus = `${process.env.BACKEND_API_URI}/api/v1/user/lockstatus/${id}`;

          const metadataResponse = await fetch(userLockStatus, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${accessToken}`,
              "X-User-Id": id
            },
          });

          const resp: LockstatusResponse = await metadataResponse.json();
          setLocked(resp.locked);
          setIsLoading(false);

        } catch (e) {
          if (e instanceof Error) {
            console.log("ITS AN ERROR in request");
            console.log(e.message);

            setIsLoading(false);
          }
        }
      };

      getLockedStatus().catch((e: unknown) => {
        if (e instanceof Error) {
          console.log("ITS AN ERROR");
          console.log(e);
          setIsLoading(false);
        }
      });
    }


  }, [getAccessTokenSilently, reload]);

  if (!isAuthenticated) {
    return <div>Error</div>
  } else if (isLoading) {
    return <div>Loading...</div>
  } else if (!locked) {
    return <div>{component}</div>
  } else {
    return <Payment />
  }
}
