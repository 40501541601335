import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState, Suspense } from "react";
import { CourseData, fetchAllCourses, postCourse } from "../../logic/course";
import { Badge, Button, CloseButton } from "react-bootstrap";
import CourseOverview from "../../components/course/CourseOverview";
import Header from "../../components/header/Header";
import NewCourse from "../../components/course/NewCourse";
import DropdownNameFilter from "../../components/filter/dropdown/DropDownNameFilter";
import Wheel from "../../components/difficulty/Wheel";
import DropDownDateFilter from "../../components/filter/dropdown/DropDownDateFilter";
import "./all_courses.css"


export default function AllCourses() {
    const { user, getAccessTokenSilently } = useAuth0();
    const [courses, setCourses] = useState<Array<CourseData>>([]);
    const [reload, setReload] = useState<boolean>(false);
    const [tagFilterNames, setTagFilterNames] = useState<Array<string>>([]);
    const [filterFavorites, setFilterFavorites] = useState(false);
    const [startsWith, setStartsWith] = useState<Array<string>>([]);
    const [filterByDate, setFilterByDate] = useState<{ field: string, order: string, tagName: string } | null>(null);

    const onNewCourseCallback = async (name: string) => {
        if (user) {
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: "https://platform.lognitiv.com/api/v1",
                    },
                });

                const id = user?.sub?.split("|").pop() || "NONE";
                const postedCourse = await postCourse(accessToken, {
                    userId: id,
                    name: name,
                    courseId: undefined,
                    difficulty: undefined,
                    favorite: undefined,
                    updated: undefined,
                    created: undefined
                })

                setCourses(courses.concat([postedCourse]));
            } catch (e) {
                console.log(e);
            }
        }
    };

    const onCourseDeleteCallback = () => {
        setReload(!reload);
    };

    const addFilterCallback = (filterName: string, startWithValue: string | null) => {
        if (startWithValue) {
            setStartsWith(startsWith.concat([startWithValue]));
        } else {
            setTagFilterNames(tagFilterNames.concat([filterName]));
        }

        setReload(!reload);
    };

    const filterDeleteCallback = (filterName: string) => {
        setTagFilterNames(tagFilterNames.filter((f) => f !== filterName));
    };

    const startsWithDeleteCallback = (startsWithVal: string) => {
        setStartsWith(startsWith.filter((f) => f !== startsWithVal));
    };

    const datesFilterCallback = (fields: { field: string, order: string, tagName: string }) => {
        setFilterByDate(fields);
        setReload(!reload);
    }

    const checkFilterActive = (): boolean => {
        if(filterFavorites || tagFilterNames.length > 0 || startsWith.length > 0) {
            return true;
        } else {
            return false;
        }
    };


    useEffect(() => {

        setCourses([]);
        const loadCourses = async () => {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: "https://platform.lognitiv.com/api/v1",
                },
            });

            const id = user?.sub?.split("|").pop() || "NONE";
            const coursesResp = await fetchAllCourses(accessToken, id, {
                filterActive: checkFilterActive(),
                startsWith: startsWith,
                names: tagFilterNames,
                favorites: filterFavorites,
                dates: filterByDate
            });

            setCourses(coursesResp.courses);
        };

        loadCourses().catch((e: unknown) => {
            if (e instanceof Error) {
                console.log("ITS AN ERROR");
                console.log(e);
            }
        });
    }, [getAccessTokenSilently, reload]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className="allcourses__view">
                <div className="wave"></div>
                <div className="wave"></div>
                <Header />
                <section className={"allcourses"}>
                    <div className={"allcourses__header"}>
                        <h6 className="headerallcourses" style={{ color: "white" }}>My Courses</h6>
                    </div>
                    <div className={"allcourses__filter"}>
                        <DropdownNameFilter courses={courses} addFilterCallback={addFilterCallback} />
                        <DropDownDateFilter selectDateFilter={datesFilterCallback} />
                        <Button variant="primary" className="allcourses__favorite-filter" onClick={() => {
                            setFilterFavorites(true);
                            setReload(!reload);
                        }} >
                            Favorites
                        </Button>
                    </div>
                    <div className={"allcourses__tags"}>
                        {filterByDate &&
                            <Badge pill bg="primary" className="filtertag" style={{ minWidth: "10%", minHeight: "50%", maxHeight: "70%" }}>
                                <div style={{ display: "flex", gap: "0.6em", alignItems: "center", height: "100%" }}> 
                                {filterByDate.tagName}
                                    <CloseButton className="closetag" style={{ width: "20%", height: "auto" }} onClick={() => {
                                        setFilterByDate(null);
                                        setReload(!reload);
                                    }} />
                                </div>
                            </Badge>
                        }
                        {filterFavorites &&
                            <Badge pill bg="primary" className="filtertag filterwidth" style={{ minWidth: "10%", minHeight: "50%", maxHeight: "70%" }}>
                                <div style={{ display: "flex", gap: "0.6em", alignItems: "center", height: "100%" }} className="closetagcontainer">
                                Favorites
                                    <CloseButton className="closetag" style={{ width: "20%", height: "auto" }} onClick={() => {
                                        setFilterFavorites(false);
                                        setReload(!reload);
                                    }} />
                                </div>
                            </Badge>
                        }
                        {startsWith.map((f, i) => <Badge pill bg="primary" key={i} className="filtertag filterwidth" >
                            <div style={{ display: "flex", gap: "0.6em", alignItems: "center", height: "100%" }} className="closetagcontainer">
                            Starts with: {f}
                                <CloseButton className="closetag" style={{ width: "20%", height: "auto" }} onClick={() => {
                                    startsWithDeleteCallback(f);
                                    setReload(!reload);
                                }} />
                            </div>
                        </Badge>)}
                        {tagFilterNames.map((f, i) => <Badge pill bg="primary" key={i} className="filtertag filterwidth">
                            <div style={{ display: "flex", gap: "0.6em", alignItems: "center", height: "100%" }} className="closetagcontainer">
                            {f}
                                <CloseButton className="closetag" style={{ width: "20%", height: "auto" }} onClick={() => {
                                    filterDeleteCallback(f);
                                    setReload(!reload);
                                }} />
                            </div>
                        </Badge>)}
                    </div>
                    <main className={"allcourses__overview"}>
                        <NewCourse onNewCourseCallback={onNewCourseCallback}
                            containerStyle={{ minWidth: "17%", minHeight: "20%", maxWidth: "18%" }}
                            contentStyle={{ height: "100%", width: "auto" }}
                        />
                        {courses
                            .map((c, i) => <CourseOverview
                                course={c}
                                key={i}
                                deleteCourseCallback={onCourseDeleteCallback}
                                reloadCallback={() => setReload(!reload)} />)}
                    </main>
                    <div className={"allcourses__diff"}>
                        <Wheel />
                    </div>
                </section>
            </div>
        </Suspense>
    );
}
