import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import Placeholder from "./placeholder.png";
import UserImageConfig from "../modals/user/UserImageConfig";
import Image from 'react-bootstrap/Image';
import "./account_popover.css";

interface AccountPopoverProps {
    closePopoverCallback(): void;
    showAccountSettingsCallback(): void;
}

type Account = {
    userId: string
    email: string
    croppedAvatar?: string
    originalAvatar?: string
    avatarSliderValue?: string
    aliasName?: string
}

export default function AccountPopover({ closePopoverCallback, showAccountSettingsCallback }: AccountPopoverProps) {
    const { user, logout, getAccessTokenSilently } = useAuth0();
    const [croppedImgSrc, setCroppedImgSrc] = useState(Placeholder);
    const [showUserImageConfig, setShowUserImageConfig] = useState(false);
    const [account, setAccount] = useState<Account | null>(null);

    const hideUserImageConfigCallback = () => {
        setShowUserImageConfig(false);
    };

    const saveImageCallback = async (imgSrc: string, originalImage: string, slideValue: number) => {
        try {
            setCroppedImgSrc(imgSrc);
    
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: "https://platform.lognitiv.com/api/v1",
                },
            });
    
            const id = user?.sub?.split("|").pop() || "NONE";
            const userLockStatus = `${process.env.BACKEND_API_URI}/api/v1/account/${id}`;
    
            const rawAccountResponse = await fetch(userLockStatus, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`,
                    "X-User-Id": id
                },
                method: "POST",
                body: JSON.stringify({
                    croppedAvatar: imgSrc,
                    originalAvatar: originalImage,
                    avatarSliderValue: slideValue
                })
            });
    
            if(rawAccountResponse.status > 399) console.log("Could not save Account changes");

            const account = await rawAccountResponse.json();
            setAccount(account);

        } catch(e: unknown) {
            console.log(e);
        }
    };

    useEffect(() => {
        const fetchAccountData = async () => {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: "https://platform.lognitiv.com/api/v1",
                },
            });

            const id = user?.sub?.split("|").pop() || "NONE";
            const userLockStatus = `${process.env.BACKEND_API_URI}/api/v1/account/${id}`;

            const rawAccountResponse = await fetch(userLockStatus, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`,
                    "X-User-Id": id
                },
            });

            if(rawAccountResponse.status > 399) throw Error("Could not get Account");

            const resp: Account = await rawAccountResponse.json();

            setAccount(resp);
            setCroppedImgSrc(resp.croppedAvatar || Placeholder);
        };

        fetchAccountData().catch((e) => console.log(e));
    }, [getAccessTokenSilently]);

    return (
        <>
            <div className={"accountpop"}>
                <div className={"accountpop__details-row "}>
                    <Image src={croppedImgSrc} onClick={() => setShowUserImageConfig(true)} className={"accountpop__image"} roundedCircle />
                    <div className={"accountpop__details-col"}>
                        <p>{account?.aliasName || "Username"}</p>
                        <p>{account?.email || user?.email}</p>
                    </div>
                </div>
                <button onClick={() => {
                    showAccountSettingsCallback();
                    closePopoverCallback();
                }}>Account Details</button>
                <button>Feedback</button>
                <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                    Log Out
                </button>
            </div>
            {showUserImageConfig && <UserImageConfig
                actualImage={account?.originalAvatar || Placeholder}
                sliderValue={account?.avatarSliderValue || 10}
                showModal={showUserImageConfig}
                showUserImageModalCallback={hideUserImageConfigCallback}
                saveImageCallback={saveImageCallback}
            />}
        </>
    );
}