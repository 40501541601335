import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import AccountPopover from "../popovers/AccountPopover";
import AccountOverview from "../modals/account/AccountOverview";
import LognitivCalendar from "../modals/calendar/LognitivCalendar";
import Bell from "./bell.svg";
import Calendar from "./calendar.svg";
import User from "./user.svg";
import Home from "./home.svg";
import "./header_actions.css";


export default function HeaderActions() {
    const navigate = useNavigate();
    const [showPopover, setShowPopover] = useState(false);
    const [showAccountSettings, setShowAccountSettings] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);

    const onHomeClick = () => {
        navigate("/dashboard");
    };

    const closePopoverCallback = () => {
        setShowPopover(false);
    };

    const showAccountSettingsCallback = () => {
        setShowAccountSettings(true);
    };

    return (
        <div className={"actions__container"}>
            <div className={"actions"}>
                <div>
                    <img className={"actions__icon"} src={Home} onClick={onHomeClick} />
                </div>
                <div>
                    <img className={"actions__icon"} src={Bell} />
                </div>
                <div>
                    <img className={"actions__icon"} src={Calendar} onClick={() => setShowCalendar(true)} />
                </div>
                <div>
                    <OverlayTrigger
                        trigger="click"
                        key={"bottom"}
                        show={showPopover}
                        onToggle={(nextShow: boolean) => setShowPopover(nextShow)}
                        placement={"bottom"}
                        overlay={
                            <Popover id={"popover-positioned-bottom"} style={{backgroundColor: "white", minWidth: "14%", border: "none"}}>
                                <Popover.Header as="h3" style={{backgroundColor: "#10d4b4", color:"white", display:"flex", justifyContent:"center"}}>{"My Account"}</Popover.Header>
                                <Popover.Body>
                                    <AccountPopover showAccountSettingsCallback={showAccountSettingsCallback} closePopoverCallback={closePopoverCallback}/>
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <img className={"actions__icon"} src={User} />
                    </OverlayTrigger>
                </div>
            </div>
            <AccountOverview show={showAccountSettings} hide={() => setShowAccountSettings(false)} />
            <LognitivCalendar showCalendar={showCalendar} hideCalendar={() => setShowCalendar(false)} />
        </div>
    );
}