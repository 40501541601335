import { CourseData } from "../../logic/course";
import PlayIcon from "./play.svg";
import CourseAnalytics from "./statistics.svg";
import "./course.css";
import { useNavigate } from "react-router-dom";

interface CourseProps {
    course: CourseData
}

export default function Course({ course }: CourseProps) {
    const navigate = useNavigate();

    return (
        <div className={"course"}>
            <div className={"course__content"}>
                <div style={{display: "flex", justifyContent: "center", maxWidth: "100%"}}>
                    <p>{course.name}</p>
                </div>
                <div className={"course__icon-row"}>
                    <img className={"course__icon"} src={PlayIcon} onClick={() => {
                        console.log("redirecting");
                        navigate("/driver");
                    }} />
                    <img className={"course__icon"} src={CourseAnalytics} />
                </div>
            </div>
        </div>
    );
}
