import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { CourseData, fetchLatestOpened, postCourse } from "../../logic/course";
import Course from "../course/Course";
import NewCourse from "../course/NewCourse";
import "./footer.css";

export default function DashboardFooter() {
    const { user, getAccessTokenSilently } = useAuth0();
    const [courses, setCourses] = useState<Array<CourseData>>([]);
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();


    const onNewCourseCallback = async (name: string) => {
        if (user) {
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: "https://platform.lognitiv.com/api/v1",
                    },
                });

                const id = user?.sub?.split("|").pop() || "NONE";
                await postCourse(accessToken, {
                    userId: id, 
                    name: name,
                    courseId: undefined,
                    difficulty: undefined,
                    favorite: undefined,
                    updated: undefined,
                    created: undefined
                })

                setReload(!reload);
            } catch (e) {
                console.log(e);
            }
        }
    };

    const onViewAllCoursesCallback = () => {
        navigate("/courses");
    };

    useEffect(() => {
        const loadCourses = async () => {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: "https://platform.lognitiv.com/api/v1",
                },
            });

            const id = user?.sub?.split("|").pop() || "NONE";
            const coursesResp = await fetchLatestOpened(accessToken, id);

            if(coursesResp.courses !== undefined) {
                setCourses(coursesResp.courses);
            }
        };

        loadCourses().catch((e: unknown) => {
            if (e instanceof Error) {
                console.log("ITS AN ERROR");
                console.log(e);
            }
        });
    }, [getAccessTokenSilently, reload]);

    return (
        <div className={"footer"}>
            <div className={"footer__header-row"}>
                <p>My Courses</p>
            </div>
            <div className={"footer__side-row"}>
                <p className={"side-row__all"} onClick={onViewAllCoursesCallback}>View all</p>
            </div>
            <div className={"footer__courses-row"}>
                <NewCourse onNewCourseCallback={onNewCourseCallback} />
                {courses && courses.map((c, i) => <Course course={c} key={i}/>)}
            </div>
        </div>
    );
}
