import { useAuth0 } from "@auth0/auth0-react";
import "./composeApp.mjs";

export default function DriverSeat() {
    const { isAuthenticated } = useAuth0();


    if(!isAuthenticated) {
        return <div>Error</div>
    }else {
        return (
            <div id="driver-seat"></div>
        );
    }

}