export type SubscriptionInfoResponse = {
    managementLink: string
    expires: string
    subscriptionId: string
}

export async function fetchSubscription(accessToken: string, userId: string): Promise<SubscriptionInfoResponse> {
    const subscriptionFetch = `${process.env.BACKEND_API_URI}/api/v1/account/subscription/${userId}`;

    const resp = await fetch(subscriptionFetch, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
            "X-User-Id": userId
        },
    });

    const subscriptionResponse: SubscriptionInfoResponse = await resp.json();
    return subscriptionResponse;
}