export type CourseData = {
    userId: string
    courseId: string | undefined
    name: string
    difficulty: number | undefined
    favorite: boolean | undefined
    updated: Date | undefined
    created: Date | undefined
}

export type AllCoursesResponse = {
    courses: Array<CourseData>
}

export async function fetchAllCourses(
    accessToken: string,
    userId: string,
    filters: {
        filterActive: boolean,
        startsWith: Array<string>,
        names: Array<string>,
        favorites: boolean,
        dates: { field: string, order: string, tagName: string } | null
    }
): Promise<AllCoursesResponse> {
    const url = new URL(`${process.env.BACKEND_API_URI}/api/v1/user/active/course/${userId}`);
    filters.startsWith.forEach((f) => url.searchParams.append("starts", f));
    filters.names.forEach((n) => url.searchParams.append("names", n));
    url.searchParams.set("filter", filters.filterActive.toString());
    url.searchParams.set("fav", filters.favorites.toString());
    url.searchParams.set("sort", filters.dates?.field ?? "created");
    url.searchParams.set("dir", filters.dates?.order ?? "asc");

    const metadataResponse = await fetch(url.toString(), {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
            "X-User-Id": userId
        },
    });


    const resp: AllCoursesResponse = await metadataResponse.json();
    return resp;
}

export async function fetchLatestOpened(accessToken: string, userId: string): Promise<AllCoursesResponse> {
    const courseFetchAll = `${process.env.BACKEND_API_URI}/api/v1/user/active/course/${userId}/opened`;

    const metadataResponse = await fetch(courseFetchAll, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
            "X-User-Id": userId
        },
    });


    const resp: AllCoursesResponse = await metadataResponse.json();
    return resp;
}

export async function postCourse(accessToken: string, course: CourseData): Promise<CourseData> {
    const courseFetchAll = `${process.env.BACKEND_API_URI}/api/v1/user/active/course`;

    const metadataResponse = await fetch(courseFetchAll, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
            "X-User-Id": course.userId
        },
        method: "POST",
        body: JSON.stringify(course)
    });

    if (metadataResponse.status > 399) {
        throw Error("Could not post course");
    }

    const resp: CourseData = await metadataResponse.json();
    return resp;
}

export async function patchCourse(accessToken: string, course: CourseData): Promise<CourseData> {
    const courseFetchAll = `${process.env.BACKEND_API_URI}/api/v1/user/active/course/${course.courseId}`;

    const metadataResponse = await fetch(courseFetchAll, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
            "X-User-Id": course.userId
        },
        method: "PATCH",
        body: JSON.stringify(course)
    });

    if (metadataResponse.status > 399) {
        throw Error("Could not post course");
    }

    const resp: CourseData = await metadataResponse.json();
    return resp;
}

export async function deleteCourse(accessToken: string, course: CourseData): Promise<void> {
    const courseDelete = `${process.env.BACKEND_API_URI}/api/v1/user/active/course/${course.courseId}`;

    const metadataResponse = await fetch(courseDelete, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
            "X-User-Id": course.userId
        },
        method: "DELETE"
    });


    if (metadataResponse.status > 399) {
        console.log("ERROR");
    }
}
