import './scss/styles.scss'
import "./app.css";
import { Outlet } from "react-router-dom";


export default function App() {

  return (
    <div>
      <Outlet />
    </div>
  );
}