import { useNavigate } from "react-router-dom";
import Logo from "../../resources/icons/logo_white.png";
import "./header_logo.css";

export default function HeaderLogo() {
    const navigate = useNavigate();

    const onHomeClick = () => {
        navigate("/dashboard");
    };
    
    return <img className={"header__logo"} onClick={onHomeClick} src={Logo} />
}