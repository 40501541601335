import KnowledgeGraph from "./graph.svg";
import Notes from "./notetaking.svg";
import Reflection from "./reflect.svg";
import StudySession from "./studysession.svg";
import "./side.css";

export default function DashboardSide() {
    return (
        <div className={"side"}>
            <div className={"side__actions"}>
                <div>
                <img className={"action__icon"} src={StudySession} />
                </div>
                <div>
                <img className={"action__icon"} src={Reflection}  />
                </div>
                <div>
                    <img className={"action__icon"} src={Notes} />
                </div>
                <div>
                    <img className={"action__icon"} src={KnowledgeGraph} />
                </div>
            </div>
        </div>
    );
}

