import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Arrow from "./arrowdown.svg"
import "./drop_name_filter.css"

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        className={"name-filter__toggle"}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <div className={"name-filter__btn-row"}>
            {children}
            <img src={Arrow} className='arrowdown' />
        </div>
    </a>
))

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <ul className="list-unstyled">
                    {React.Children.toArray(children)}
                </ul>
            </div>
        );
    },
);


export default function DropdownNameFilter({ selectDateFilter }) {

    return (
        <Dropdown className='filterwidth'>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <p>Filter by Date</p>
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item onClick={() => selectDateFilter({field: "created", order: "desc", tagName: `Sorted: created descending`})}>Created descending</Dropdown.Item>
                <Dropdown.Item onClick={() => selectDateFilter({field: "created", order: "asc", tagName: `Sorted: created ascending`})}>Created ascending</Dropdown.Item>
                <Dropdown.Item onClick={() => selectDateFilter({field: "updated", order: "desc", tagName: `Sorted: updated descending`})}>Updated descending</Dropdown.Item>
                <Dropdown.Item onClick={() => selectDateFilter({field: "updated", order: "asc", tagName: `Sorted: updated ascending`})}>Updated ascending</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}