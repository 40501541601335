import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Arrow from "./arrowdown.svg"
import "./drop_name_filter.css"


// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        className={"name-filter__toggle"}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <div className={"name-filter__btn-row"}>
            {children}
            <img src={Arrow} className='arrowdown' />
        </div>
    </a>
))

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy, addFilterCallback }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <Form.Control
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {value && <Dropdown.Item eventKey="0" key={0} onClick={() => addFilterCallback(`Starts with ${value}`, value)} >Starts with {value}</Dropdown.Item>}
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value.toLowerCase()),
                    )}
                </ul>
            </div>
        );
    },
);


export default function DropdownNameFilter({ courses, addFilterCallback }) {

    return (
        <Dropdown className='filterwidth'>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <p>Course Name</p>
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} addFilterCallback={addFilterCallback}>
                {courses && courses.map((value, key) => <Dropdown.Item eventKey={key} key={key} onClick={() => addFilterCallback(value.name, null)}>{value.name}</Dropdown.Item>)}
            </Dropdown.Menu>
        </Dropdown>
    );
}