import React from "react";
import "./wheel.scss";

export default function Wheel() {

    const onIndexSelect = (e: React.MouseEvent<HTMLDivElement>) => {
        const wheelElem = document.getElementById("diff-wheel");
        if (wheelElem) {
            const attr = wheelElem.getAttribute("data-chosen");
            if (attr && attr === e.currentTarget.id) {
                wheelElem.setAttribute("data-chosen", "0");
            } else {
                wheelElem.setAttribute("data-chosen", e.currentTarget.id);
            }
        }
    };

    return (
        <div className="wheel on" id="diff-wheel">
            <div className="arc" id="1" onClick={(e) => onIndexSelect(e)} ><i className="fas fa-home"></i></div>
            <div className="arc" id="2" onClick={(e) => onIndexSelect(e)} ><i className="fas fa-question"></i></div>
            <div className="arc" id="3" onClick={(e) => onIndexSelect(e)} ><i className="fas fa-bell"></i></div>
            <div className="arc" id="4" onClick={(e) => onIndexSelect(e)} ><i className="fas fa-camera"></i></div>
            <div className="arc" id="5" onClick={(e) => onIndexSelect(e)} ><i className="fas fa-trash-alt"></i></div>
            <div className="arc" id="6" onClick={(e) => onIndexSelect(e)} ><i className="fas fa-save"></i></div>
            <div className="arc" id="7" onClick={(e) => onIndexSelect(e)} ><i className="fas fa-flag"></i></div>
            <div className="arc" id="8" onClick={(e) => onIndexSelect(e)} ><i className="fab fa-codepen"></i></div>
        </div>
    );
}