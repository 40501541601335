import { Button, Modal } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchSubscription } from "../../../logic/account";
import "./account_overview.css";

export interface AccountOverviewProps {
    show: boolean;
    hide(): void;
}

export default function AccountOverview(props: AccountOverviewProps) {

    const { user, getAccessTokenSilently } = useAuth0();

    const onManageSubscriptionClick = async () => {

        try {
            if (user) {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: "https://platform.lognitiv.com/api/v1",
                    },
                });

                const id = user?.sub?.split("|").pop() || "NONE";

                const subscription = await fetchSubscription(accessToken, id);
                window.open(subscription.managementLink, '_blank')?.focus();
            }
        } catch (e: unknown) {
            console.log(e);
        }
    };

    return (
        <Modal
            show={props.show}
            onHide={props.hide}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className={"settings__title"}>
                    <h4>My Account</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"account-details"}>
                    <button className="account-details__button-subscriptions" onClick={onManageSubscriptionClick}>Manage my subscriptions</button>
                    <button className="account-details__button-help">Help & FAQ</button>
                    <button className="account-details__button-contact">Contact us</button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div style={{display: "flex", flexDirection:"column", gap:"1em", alignItems:"flex-end"}}>
                    <Button onClick={props.hide} className="closesettings">Close</Button>
                    <p className="privacy_settings">View our <a href="https://lognitiv.com/terms-of-service/">Terms of Service</a> and <a href="https://lognitiv.com/privacy-policy/">Privacy Policy</a> </p>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
